import React, { createContext } from "react"

export const GlobalStateContext = createContext()
export const GlobalDispatchContext = createContext()

const initialState = {
  isModalOpen: false,
  currentZajazd: {
    terminy: [],
    title: "",
    selected: "",
    predbeznyZaujem: false
  },
  listFilters: {
    sortBy: "",
    term: ""
  },
}

function reducer(state, action) {
  switch (action.type) {
    case "TOGGLE_IS_MODAL": {
      return {
        ...state,
        isModalOpen: !state.isModalOpen,
      }
    }
    case "SET_CURRENT_ZAJAZD": {
      const { newTitle, newTerminy } = action.payload;
      return {
        ...state,
        currentZajazd: {
          ...state.currentZajazd,
          title: newTitle,
          terminy: newTerminy?.termin || [],
          predbeznyZaujem: false
        }
      }
    }
    case "SET_SELECTED_TERMIN": {
      const { newSelected } = action.payload;
      return {
        ...state,
        currentZajazd: {
          ...state.currentZajazd,
          selected: newSelected,
        }
      }
    }
    case "SET_SEARCH_TERM": {
      const { newTerm } = action.payload;
      return {
        ...state,
        listFilters: {
          ...state.listFilters,
          term: newTerm,
        }
      }
    }
    case "SET_SORT_BY": {
      const { newSortBy } = action.payload;
      return {
        ...state,
        listFilters: {
          ...state.listFilters,
          sortBy: newSortBy,
        }
      }
    }
    case "SET_PREDBEZNY_TERMIN": {
      const { newTitle } = action.payload;
      return {
        ...state,
        currentZajazd: {
          ...state.currentZajazd,
          predbeznyZaujem: true,
          selected: null,
          title: newTitle,
          terminy: []
        }
      }
    }
    case "SET_PREDBEZNY_ZAUJEM": {
      return {
        ...state,
        currentZajazd: {
          ...state.currentZajazd,
          predbeznyZaujem: true,
        }
      }
    }
    default:
      throw new Error("Bad Action Type")
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider