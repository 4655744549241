import React from "react"
import GlobalContextProvider from "./src/context/GlobalContextProvider"
import "./src/assets/all.sass"

import "@fontsource/josefin-sans/200.css";
import "@fontsource/josefin-sans/300.css";
import "@fontsource/josefin-sans/400.css";
import "@fontsource/josefin-sans/600.css";
import "@fontsource/josefin-sans/700.css";

// Italic weights
import "@fontsource/josefin-sans/300-italic.css";
import "@fontsource/josefin-sans/600-italic.css";

import "animate.css/animate.min.css"

export const wrapRootElement = ({ element }) => {
    return <GlobalContextProvider>{element}</GlobalContextProvider>
}



